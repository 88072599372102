import axios from 'axios'
import helper from '@/helper'
import workItemQueries from '@/components/Works/WorkItems/workItemQueries'

export default {
  getSettings () {
    return this.sendRequest('/gorilla/system/settings', [], [])
  },

  getSystemConfigs () {
    return this.getListItems('system_settings', {
      parameter: 'currency+google_maps_api',
      limit: 1000
    }, [], {})
  },

  getObjectStates (className, locale, identifier) {
    const localizedNames = ['en', 'fi', 'se'].map(locale => ['name', locale].join('_'))
    return new Promise(resolve => {
      this.sendRequest('/api/object_states/', {
        _object_type: className,
        _identifier: identifier,
        limit: 1000,
        order: 'name_' + locale + ' asc',
      }, ['name', ...localizedNames, 'summary', 'object_type', 'identifier'])
        .then(response => {
          if (response && response.items) {
            resolve({
              items: response.items.filter(state => state.object_type === className),
            })
          } else {
            resolve(response)
          }
        })
    })
  },

  getAttributeMetadata (resource) {
    const queries = [
      '!object_type,attribute_name,attribute_type,dynamic,macro',
      'nestable,multi_language,writable,association_types,reference_class?',
      'reference_attribute?,amc_options'
    ]
    const filters = {
      _object_type: resource,
      q: queries.join(',')
    }
    filters.limit = 1000
    return this.getListItems('attribute_metadatas', filters, [], {})
  },

  getTokenFor (objectClass, referenceClass, objectToken, referenceField, queries) {
    return new Promise((resolve) => {
      axios.get(
        '/api/' + referenceClass + '/new/for/' + objectClass + '/' +
        objectToken + '/' + referenceField + '?' +
        queries.map(query => 'q[]=' + query).join('&'),
        this.requestOptions()).then((response) => {
        if (response.data) {
          resolve(response)
        }
        resolve({})
      }).catch((err) => {
        resolve(err.response)
      })
    })
  },

  getToken (objectClass, item, queries) {
    return new Promise((resolve) => {
      if (item.token) {
        // Token has already been requested for the item
        resolve(item.token)
      } else {
        axios.get('/api/' + objectClass + '/new?q%5B%5D=%40editable&' +
          queries.map(query => 'q[]=' + query).join('&'), this.requestOptions()
        ).then(response => {
          if (response.data) {
            resolve(response.data.item && response.data.item.token)
          }
          resolve(false)
        }).catch((err) => {
          resolve(err.response)
        })
      }
    })
  },

  getListItems (objectClass, filters, queries, { forClass, forId, forField }) {
    if (forId && forClass && forField) {
      if (!filters.existing) {
        filters.push({
          key: 'existing',
          value: 'true',
        })
      }
      return this.sendRequestAndSortImages('/api/' + objectClass + '/for/' + forClass +
        '/' + forId + '/' + forField, filters, queries)
    }
    return this.sendRequestAndSortImages('/api/' + objectClass + '/', filters, queries)
  },

  getWorksForHome (filters, queries) {
    return this.sendRequest('/api/works/open_works', filters, queries)
  },

  getItemInfo (objectClass, itemId, queries) {
    return this.sendRequestAndSortImages('/api/' + objectClass + '/' + itemId, [], queries)
  },

  getItemInfoForEdit (objectClass, itemId, queries, {
    targetResource, targetId, targetField,
  }) {
    const filters = {}
    if (itemId !== 'new') {
      filters.edit = true
    }
    let url = '/api/' + objectClass + '/' + itemId
    if (targetResource && targetId && targetField) {
      url = url + '/for/' + targetResource + '/' + targetId + '/' + targetField
    }
    return this.sendRequestAndSortImages(url, filters, queries)
  },

  searchOptionsByName ({
    searchString,
    objectClass,
    moduleClass,
    tokenOrId,
    fieldName,
    parent,
    parentField,
    extraFilters = {},
  }) {
    const filters = []
    let url = '/api/' + objectClass
    if (tokenOrId) {
      url = '/api/' + objectClass + '/for/' + moduleClass + '/' + tokenOrId + '/' + fieldName
    }
    if (parent && parent['@class']) {
      url = '/api/' + (objectClass || 'any') + '/for/' + moduleClass + '/' + tokenOrId + '/' +
        fieldName
      filters.push({
        key: '~path',
        value: parent['@class'] + ':' + (parent.token || parent.id) + '%20' + parentField,
      })
    }
    const queries = [
      ['summary']
    ]
    let limit = 100
    if (searchString) {
      limit = 20
      filters.push({
        key: '_summary',
        value: searchString,
      })
    }
    filters.push({
      key: 'limit',
      value: limit,
    })
    filters.push({
      key: '_in_operation',
      value: true,
    })
    // Add extra filters
    // For example when organization is used, filter activity parks options with selected organization
    Object.keys(extraFilters).forEach(key => {
      filters.push({
        key, value: extraFilters[key],
      })
    })
    return this.sendRequest(url, filters, queries)
  },

  fetchTokenForItem (parent, itemField, queries) {
    const parentId = parent.token || parent.id
    return this.getTokenFor(
      helper.objectClassUnderscoredName(parent['@class']),
      itemField,
      parentId,
      itemField,
      queries,
    )
  },

  getExcelReport ({ model, id, ids = [], locale }) {
    const apiUrls = {
      works: '/api/works/playcare_report.xls?ids=' + ids.join(',') + '&locale=' + locale,
      activityParks: '/api/organizations/playcare_report/' + id + '.xls?locale=' + locale,
    }
    window.open(apiUrls[model])
  },

  getRatingsExcel ({ ids = [], order = 'name asc' } = {}) {
    window.open('/api/ratings.xls?order=' + order + '&_organization_id=(' + ids.join(',') + ')')
  },
}
