import Vue from 'vue'
import api from '@/store/api'
import worksQueries from '@/components/Works/worksQueries'
import moment from 'moment'
import i18n from '@/locale/i18n'

const clientErrorCodes = ['error', 400, 401, 402, 403, 404, 405, 406, 407, 408, 409, 410,
  411, 412, 413, 414, 415, 416, 417, 418, 421, 422, 423, 424, 425, 426, 428, 429, 431, 451]
const serverErrorCodes = ['fatal', 500, 501, 502, 503, 504, 505, 506, 507, 508, 510, 511]

const isObject = item => {
  return item && typeof item === 'object' && !Array.isArray(item)
}

const mergeDeep = (target, source) => {
  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} })
        mergeDeep(target[key], source[key])
      } else {
        Object.assign(target, { [key]: source[key] })
      }
    }
  }
  return target
}

const checkErrorMessagesIfSessionHasExpired = errors => {
  let loggedOut = false
  if (errors) {
    errors.forEach((message) => {
      if (message.key === 'app.access.not_authenticated') {
        loggedOut = true
      }
    })
  }
  return loggedOut
}

const getMessageRandomKey = () => {
  return Math.random().toString(36)
}

const messageKeyExists = (key, messages) => {
  return messages.filter(message => message.key === key).length > 0
}

export default {
  deleteItem: ({ dispatch }, { id, resource }) => {
    return new Promise(resolve => {
      api.deleteItem(resource, id).then(response => {
        dispatch('globalErrorDisplay', response)
        resolve(response && response.data && response.data.status === 'ok')
      })
    })
  },

  addAppMessage: ({ state, dispatch }, message) => {
    const defaultExpireTime = 3000
    const defaultErrorExpireTime = 15000
    if (!message.key) { message.key = getMessageRandomKey() }
    if (messageKeyExists(message.key, state.appMessages)) { return }
    if (!message.expires && !message.sticky && ['error', 'warning'].includes(message.type)) {
      message.expires = defaultErrorExpireTime
    } else if (!message.expires && !message.sticky) {
      message.expires = defaultExpireTime
    }
    // Set expire timer
    if (message.expires) {
      setTimeout(() => {
        dispatch('clearAppMessageByKey', message.key)
      }, message.expires)
    }
    // Add message to store
    Vue.set(state.appMessages, state.appMessages.length, message)
  },

  addAppMessages: ({ dispatch }, messages) => {
    if (!messages) { return }
    messages.forEach(message => {
      dispatch('addAppMessage', message)
    })
  },

  clearAppMessageByKey: ({ state }, key) => {
    const index = state.appMessages.map(message => message.key).indexOf(key)
    if (index === -1) { return }
    Vue.delete(state.appMessages, index)
  },

  showMessage: ({ dispatch }, { message, type }) => {
    dispatch('addAppMessage', {
      message,
      type: type || 'info',
    })
  },

  setUserId: ({ state }, userId) => {
    state.userId = userId
  },

  globalErrorDisplay: ({ commit, dispatch }, response) => {
    if (!response) {
      return false
    }
    if (checkErrorMessagesIfSessionHasExpired(response.messages || response.data?.messages)) {
      dispatch('logout', { root: true })
      return true
    }
    if (response && clientErrorCodes.includes(response.status)) {
      const messages = response.messages || (response.data && response.data.messages)
      dispatch('addAppMessage', {
        message: 'ERROR (' + response.status + ')<br>' +
          (messages ? messages.map(m => m.text ? m.text : i18n.t(m.key)).join('<br>') : 'UNKNOWN'),
        type: 'error'
      })
      return true
    } else if (response && serverErrorCodes.includes(response.status)) {
      const debug = response.debug || (response.data && response.data.debug)
      dispatch('addAppMessage', {
        message: 'ERROR (' + response.status + ')<br>' + (debug ? debug.map(m => m.text).join('<br>') : 'UNKNOWN'),
        type: 'error'
      })
      return true
    } else if (!response) {
      dispatch('addAppMessage', {
        message: 'UNKNOWN HTTP API CALL ERROR<br>',
        type: 'error',
      })
      return true
    }
    return false
  },

  loadSupportingData: ({ state, dispatch }) => {
    const promises = []
    promises.push(dispatch('getSystemConfigs'))
    promises.push(dispatch('getWorkObjectStates'))
    promises.push(dispatch('getProductInstanceTypes'))
    promises.push(dispatch('getShoppingCartItems'))
    promises.push(dispatch('getAnnouncements'))
    Promise.all(promises).then(responses => {
      state.supportedDataLoaded = true
    })
  },

  getSettings: ({ state, dispatch }) => {
    return new Promise(resolve => {
      api.getSettings().then(response => {
        dispatch('globalErrorDisplay', response)
        if (response.items) {
          state.settings = response.items
        }
        resolve(response)
      })
    })
  },

  getShoppingCartItems: ({ state, dispatch }) => {
    return new Promise(resolve => {
      if (!state.userInfo.person || !state.userInfo.person.id) { resolve({}) }
      api.getListItems('works', {
        _main_object_state_id: state.workStateIds.shoppingCart,
        _shopping_cart_person_id: state.userInfo.person.id,
        order: 'created_at desc',
        offset: 0,
        limit: 1000,
      }, worksQueries.forList, {}).then(response => {
        dispatch('globalErrorDisplay', response)
        if (response.items) {
          state.shoppingCartItems = response.items.map(item => {
            item.quantity = 1
            return item
          })
        }
        resolve(response)
      })
    })
  },

  getAnnouncements: ({ state, dispatch }) => {
    return new Promise(resolve => {
      api.getListItems('announcements', {
        _shown_from_date: '..' + moment().format('YYYY-MM-DD'),
        _shown_until_date: moment().format('YYYY-MM-DD') + '..',
      }, [], {}).then(response => {
        dispatch('globalErrorDisplay', response)
        if (response.items) {
          state.announcements = response.items
        }
        resolve(response)
      })
    })
  },

  getWorkObjectStates: ({ state, dispatch }) => {
    const systemConfig = {}
    return new Promise(resolve => {
      api.getObjectStates('Work', state.locale, '-proposed').then(response => {
        dispatch('globalErrorDisplay', response)
        if (response.items) {
          state.workObjectStates = response.items
        }
        resolve(response)
      })
    })
  },

  clearActivityParkFilters: ({ state }) => {
    state.globalFilters.activity_park = []
    state.activityParksListOptions = state.activityParksOldState.activityParksListOptions
    state.globalFilters = state.activityParksOldState.globalFilters
    state.activityParksItems = state.activityParksOldState.activityParksItems
  },

  getProductInstanceTypes: ({ state, dispatch }) => {
    return new Promise(resolve => {
      api.getListItems('product_instance_types', {
        order: 'summary asc',
        offset: 0,
        limit: 1000,
      }, ['summary', 'product_instance_category'], {}).then(response => {
        dispatch('globalErrorDisplay', response)
        if (response.items) {
          state.productInstanceTypes = response.items
        }
        resolve(response)
      })
    })
  },

  getSystemConfigs: ({ state, dispatch }) => {
    const systemConfig = {}
    return new Promise(resolve => {
      api.getSystemConfigs().then(response => {
        dispatch('globalErrorDisplay', response)
        if (response.items) {
          response.items.forEach(config => {
	    console.log(config)
            systemConfig[config.key] = config.value
          })
          state.systemConfig = systemConfig
        }
        resolve(response)
      })
    })
  },

  sendProcessEventAction: ({ dispatch }, { objectClass, itemId, action, queries, }) => {
    return new Promise(resolve => {
      api.sendProcessEventAction(objectClass, itemId, action, queries).then(response => {
        dispatch('globalErrorDisplay', response)
        resolve((response && response.item) || null)
      })
    })
  },
}
